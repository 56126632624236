import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import bgSlider from "../../../assets/images/slider-bg1.jpg";
import pizzaImage from "../../../assets/images/pz.png";
import vegiz from "../../../assets/images/vegiz.png";
import vegiterianLogo from "../../../assets/images/vegitarian.jpeg";
import { getDynamicSlider } from "../../../services";
import LoadingLayout from "../../../layouts/LoadingLayout";
import loadingImg from "../../../assets/images/loader-light.gif";

const HeroSlider = () => {
  const fixedBgUrl = bgSlider;
  const containerStyle = {
    background: `url(${fixedBgUrl}) no-repeat center center`,
  };
  const [getSlider, setGetSlider] = useState([]);
  // const [loading, setLoading] = useState(false);
  const getsliderData = async () => {
    // setLoading(true);
    await getDynamicSlider()
      .then((res) => {
        // setLoading(false);
        setGetSlider(res.data);
        console.log("Slider data is added here", res.data);
      })
      .catch((error) => {
        // setLoading(false);
        console.log("ERROR From Get Dynamic Slider API: ", error);
      });
  };

  useEffect(() => {
    getsliderData();
  }, []);

  return (
    <>
      <div className="banner slider1 new-block">
        <div className="fixed-bg" style={containerStyle}></div>

        <OwlCarousel
          className="slider owl-carousel owl-theme"
          autoplay={true}
          autoplayTimeout={5000}
          loop={true}
          margin={10}
          nav={true}
          dots={false}
          rewind={true}
          lazyLoad={true}
          responsive={{
            0: {
              items: 1,
            },
            600: {
              items: 1,
            },
            1000: {
              items: 1,
            },
          }}
        >
          {/* {loading ? (
            <div className="d-flex w-100 vh-100 justify-content-center align-items-center">
              <img
                src={loadingImg}
                alt=""
                style={{ width: "2rem", height: "2rem" }}
              />
            </div>
          ) : (
            <> */}
          {/* Upcomming Store Location */}
          {getSlider &&
            getSlider?.length > 0 &&
            getSlider.map((data, index) => {
              return (
                <>
                  <div className="item" key={index}>
                    {data.is_static === "true" ? (
                      <>
                        <div className="slider-block slide1 new-block">
                          <div className="container-fluid">
                            <div className="row">
                              <div className="col-lg-6 col-md-6 col-sm-6 order-md-1 order-2">
                                <div className="text-block">
                                  <h5 className="text-stl2">{data.subTitle}</h5>
                                  <h1 className="text-stl1">{data.title}</h1>
                                  <div
                                    className="number-block"
                                    data-animation-in="fadeInUp"
                                    data-animation-out="animate-out fadeOutRight"
                                  >
                                    <div className="text-center">
                                      <Link
                                        to={data.url}
                                        className="btn1 stl2 text-decoration-none"
                                      >
                                        {data.btnName}
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-6 col-sm-6 order-md-2 order-1">
                                <div className="img-block img2">
                                  <div className="img-holder">
                                    <img
                                      src={pizzaImage}
                                      alt=""
                                      className="img-fluid"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="slider-block slide1 new-block">
                          <div className="storeSlide container-fluid">
                            <div className="row gx-3 justify-content-center align-items-center">
                              <div className="col-md-10 col-sm-12 pb-5">
                                <div className="title text-center">
                                  <h1 className="">{data.title}</h1>
                                </div>
                              </div>
                              {data?.lineentries?.length > 0 &&
                                data?.lineentries?.map((item, key) => {
                                  return (
                                    <div
                                      className="col-md-10 col-sm-12 px-3"
                                      key={key}
                                    >
                                      <div className="storeList mb-4">
                                        <h2 className="">
                                          {item.store_address}
                                        </h2>
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </>
              );
            })}
          {/* <div className="item">
            <div className="slider-block slide1 new-block">
              <div className="container-fluid">
                <div className="row align-items-center justify-content-center">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="d-flex justify-content-center sliderImage">
                      <div className="">
                        <div className="">
                          <img src={vegiz} alt="" className="img-fluid image" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* <div className="item"></div>
          <div className="item">
            <div className="slider-block slide1 new-block">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-6 order-md-1 order-2">
                    <div className="text-block">
                      <h5 className="text-stl2">100% Vegetarian</h5>
                      <h1
                        className="text-stl1"
                        data-animation-in="lightSpeedIn"
                        data-animation-out="animate-out fadeOutRight"
                      >
                        special pizza's and combo's
                      </h1>
                      <div
                        className="number-block"
                        data-animation-in="fadeInUp"
                        data-animation-out="animate-out fadeOutRight"
                      >
                        <div className="text-center">
                          <Link
                            to="/special-list"
                            className="btn1 stl2 text-decoration-none"
                          >
                            View
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 order-md-2 order-1">
                    <div className="img-block img2">
                      <div className="img-holder">
                        <img src={pizzaImage} alt="" className="img-fluid" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* </>
          )} */}
        </OwlCarousel>
      </div>
    </>
  );
};

export default HeroSlider;
